<template>
  <el-dialog :title="(type===5||parentType===5) ?'分解信息' : '分解信息（下达)'" 
  :close-on-click-modal="false" :visible.sync="visible">
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%;" append-to-body>
      <el-table-column prop="orkName" header-align="center" align="center" :show-overflow-tooltip="true" label="指标名称">
      </el-table-column>
      <el-table-column prop="okrYear" header-align="center" align="center" label="指标年份">
      </el-table-column>
      <el-table-column prop="issuedState" header-align="center" align="center" label="下达状态" v-if="type!==5 && parentType !== 5">
        <template slot-scope="scope">
          <sysDict type="issued_state" :value="scope.row.issuedState"></sysDict>
        </template>
      </el-table-column>
      <el-table-column prop="resolveUser" header-align="center" align="center" label="分解人">
        <template slot-scope="scope">
          <user :value="scope.row.resolveUser" />
        </template>
      </el-table-column>
      <el-table-column prop="addUserNumber" header-align="center" align="center" label="新增用户数">
      </el-table-column>
      <el-table-column prop="valueAddedIncome" header-align="center" align="center" label="增值收入">
      </el-table-column>
      <el-table-column prop="publicBusinessIncome" header-align="center" align="center" label="大众业务收入">
      </el-table-column>
      <el-table-column prop="developmentUser" header-align="center" align="center" label="5G用户发展用户">
      </el-table-column>
      <el-table-column prop="resolveMonth" header-align="center" align="center" label="分解月份">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="scope.row.issuedState == '01' &&type!==5 && parentType !== 5"
            @click="addOrUpdateHandle(scope.row.id, scope.row.mainId, scope.row.orkName, scope.row.resolveMonth, scope.row.okrYear)">下达</el-button>
          <el-button type="text" size="small" class="del-button" v-if="scope.row.issuedState == '01'"
            @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <issued v-if="issudeVisible" ref="issude" @refreshDataList="getDataList"></issued>
</el-dialog>
</template>
<script>


import issued from './issued';
import { getList, deleteData } from '@/api/dataflow/olxpokrmanagedetail.js'
export default {
  data() {
    return {
      visible: false,
      issudeVisible: false,
      dataForm: {
        id: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      type: '',
      parentType: '',
    }
  },
  components: {
    issued
  },
  activated() {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    //   this.getDataList();
  },
  computed: {
    regionCode: {
      get() {
        const newregionId = this.$store.state.user.regionCode;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    }
  },
  methods: {
    init(id, type, parentType) {
      this.visible = true
      this.dataForm.id = id
      this.type = type
      this.parentType = parentType
      this.getDataList()
    },
    refresh() {
      // this.$router.push({name:'dataflow_olxpokrmanagemain',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      const params_ = {
        'mainId': this.dataForm.id,
        'page': this.pageIndex,
        'limit': this.pageSize,
      }
      getList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("getList=>" + JSON.stringify(data))
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
          if (data.data.page != this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          this.dataList = []
          this.totalCount = 0
          this.pageIndex = 1
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    //下达
    addOrUpdateHandle(id, mainId, porkName, month, okrYear) {
      this.issudeVisible = true
      this.$nextTick(() => {
        this.$refs.issude.init(id, mainId, porkName, month, okrYear)
      })
    },
    // 删除
    deleteHandle(row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})
      // console.log("ids=>"+ids)
      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })

            const totalPage = Math.ceil((this.totalCount - 1) / this.pageSize);
            // 总页数
            this.pageIndex = this.pageIndex > totalPage ? totalPage : this.pageIndex;
            this.pageIndex = this.pageIndex < 1 ? 1 : this.pageIndex;


            this.getDataList()
            this.$emit('refreshDataList', true)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    }
  }
}
</script>