<template>
    <el-dialog
      title="下达"
      :close-on-click-modal="false"
      :visible.sync="visible"
      append-to-body
      >
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="指标名称" prop="pokrName">
        <el-input v-model="dataForm.pokrName" disabled placeholder="指标名称"></el-input>
      </el-form-item>
      <el-form-item label="指标下达人"  prop="userName">
        <el-input v-model="dataForm.userName" disabled placeholder="指标下达人"></el-input>
      </el-form-item>
      <!-- <el-form-item label="公司级别" prop="areaType">
        <sysDictSelect v-model="dataForm.areaType" dictType="org_type" placeholder="公司级别"></sysDictSelect>
      </el-form-item> -->
      <el-form-item label="下级区域" prop="checkedCities">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="dataForm.checkedCities" @change="handleCheckedCitiesChange()">
              <el-checkbox v-for="county in cities" :label="county.id" :key="county.code">{{county.name}}
              </el-checkbox>
          </el-checkbox-group>
      </el-form-item>
      <el-form-item label="新增用户" prop="addUserNumber" >
          <el-input-number  v-model="dataForm.addUserNumber"  :min="0" placeholder="请输入新增用户数"></el-input-number>
        </el-form-item>
        <el-form-item label="增值收入" prop="valueAddedIncome" >
          <el-input-number  v-model="dataForm.valueAddedIncome"  :min="0" placeholder="请输入增值收入数"></el-input-number>
        </el-form-item>
        <el-form-item label="大众业务收入" prop="publicBusinessIncome" >
          <el-input-number  v-model="dataForm.publicBusinessIncome"  :min="0" placeholder="请输入大众业务收入数"></el-input-number>
        </el-form-item>
        <el-form-item label="5G用户发展用户" prop="developmentUser" >
          <el-input-number  v-model="dataForm.developmentUser"  :min="0" placeholder="请输入5G用户发展用户数"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import {saveOrUpdate } from '@/api/dataflow/olxcokrmanagemain.js'
  import {getById } from '@/api/dataflow/olxpokrmanagedetail.js'
  import { childCode } from '@/api/sys/region.js'
    export default {
      data () {
        return {
          visible: false,
          isOnSubmit: false,
          checkAll: false,
          // cities: cityOptions,
          cities: new Map(),
          isIndeterminate: true,
          dataForm: {
            id: null,
            pokrId: '',
            pokrName: '',
            userName: '',
            areaType: '',
            areaCode: '',
            okrState: '',
            reciveDate: '',
            resolveDate: '',
            execDate: '',
            addUserNumber: '',
            resolveMonth: '',
            valueAddedIncome: '',
            publicBusinessIncome: '',
            developmentUser: '',
            checkedCities: [],
            okrYear:''
          },
          dataRule: {
            checkedCities: [
              { required: true, message: '下级区域不能为空', trigger: 'blur' }
            ],
          }
        }
      },
      computed: {
            userId:{
            get(){
                const uID = this.$store.state.user.id;
                if(uID !== undefined){
                return String(uID); 
                }else{
                return ''
                }
            }
            },
    },
      methods: {
        init (id,mainId,pokrName,month,okrYear) {
          this.dataForm.checkedCities=[],
          this.checkAll= false,
          this.visible = true
          this.isOnSubmit = false
          this.dataForm.id = id
          this.dataForm.pokrId = mainId
          this.dataForm.pokrName = pokrName
          this.dataForm.resolveMonth = month
          this.dataForm.okrYear = okrYear
          //获取登录时的code
          const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
          this.childCodeName(code)
          this.dataForm.userName = this.$cookie.get("Username");
          this.$nextTick(() => {
            this.$refs['dataForm'].resetFields()
            if (this.dataForm.id) {
              getById(id).then(({data}) => {
                if (data && data.code === 0) {
                  this.dataForm.pokrId = data.data.pokrId
                  this.dataForm.pokrName = data.data.pokrName
                  this.dataForm.addUserNumber = data.data.addUserNumber
                  this.dataForm.developmentUser = data.data.developmentUser
                  this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
                  this.dataForm.valueAddedIncome = data.data.valueAddedIncome
                  if(data.data.userName){
                    this.dataForm.userName = data.data.userName
                  }else{
                    this.dataForm.userName = this.$cookie.get("Username");
                  }
                  if(data.data.checkedCities){
                      this.dataForm.checkedCities = data.data.checkedCities
                      this.handleCheckedCitiesChange()
                    }
                }
              }).catch((err) => {
                this.$message.error(err)
              })
            }
          })
        },
  
        //下属复选框
        // handleCheckAllChange(val) {
        //   this.checkedCities = val ? cityOptions : [];
        //   this.isIndeterminate = false;
        // },
        handleCheckAllChange () {
        if (this.checkAll) {
          var aa = Object.values(this.cities)
          if (aa != null && aa.length > 0) {
            for (let lists of Object.values(this.cities)) {
              this.dataForm.checkedCities.push(lists.code)
            }
          }
          this.checkAll = true
        } else {
          this.dataForm.checkedCities = []
        }

      },
        // handleCheckedCitiesChange(value) {
        //   let checkedCount = value.length;
        //   this.checkAll = checkedCount === this.cities.length;
        //   this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        // },
        handleCheckedCitiesChange () {
        let checkedCount = this.dataForm.checkedCities.length
        let allCount = 0
        var aa = Object.values(this.cities)
        if (aa != null && aa.length > 0) {
            allCount = aa.length
        }
        this.checkAll = checkedCount === allCount
      },
        //获取下级区域
        childCodeName(code) {
            childCode(code).then(({data}) => {
              if (data && data.code === 0) {
                  this.cities = data.data
              }
            })
        },
  
        // 表单提交
        dataFormSubmit () {
          this.$refs['dataForm'].validate((valid) => {
            if (valid) {
                this.isOnSubmit = true
              const data_ = {
                'mainId':this.dataForm.id,
                'pokrId': this.dataForm.pokrId,
                'pokrName': this.dataForm.pokrName,
                'userName': this.dataForm.userName,
                'areaType': this.dataForm.areaType,
                'addUserNumber': this.dataForm.addUserNumber,
                'resolveMonth': this.dataForm.resolveMonth,
                'valueAddedIncome': this.dataForm.valueAddedIncome,
                'publicBusinessIncome': this.dataForm.publicBusinessIncome,
                'developmentUser': this.dataForm.developmentUser,
                'checkedCities': this.dataForm.checkedCities,
                'userId':this.userId,
                'okrYear':this.dataForm.okrYear
              }
              saveOrUpdate(data_).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 500
                  })
                  this.visible = false
                  this.$emit('refreshDataList')
                } else {
                  this.$message.error(data.msg)
                }
              }).catch((err) => {
                this.$message.error(err)
              }).finally(()=>{
                this.isOnSubmit = false
              })
            }
          })
        }
      }
    }
  </script>
  