import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/dataflow/olxcokrmanagemain/list'),
          method: 'get',
          params: params
        })
} 

// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/dataflow/olxcokrmanagemain/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}
//获取所属区域
export function getAreaName(code) {
    return http({
        url: http.adornUrl(`/sys/region/getAreaName/${code}`),
        method: "get"
    })
}
// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxcokrmanagemain/info/${id}`),
        method: "get"
        })
}

// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/dataflow/olxcokrmanagemain/saveOrUpdate`
            ),
            method: "post",
            data: data
        })
}

//执行
export function getexecuteUpdate(id) {
    return http({
        url: http.adornUrl(`/dataflow/olxcokrmanagemain/getexecuteUpdate/${id}`),
        method: "get"
    })
}

//导出excel
export function exportToExcel(config){
  
  var iform = document.createElement("iframe");
  iform.setAttribute("name", "downloadIframe");
  iform.setAttribute("style", "display:none");
  document.body.appendChild(iform);

  var form = document.createElement("form");
  document.body.appendChild(form);
  form.setAttribute("method", "GET");

  var input = document.createElement("input")
  input.id = "areaCode";
  input.name = "areaCode";
  input.setAttribute("type", "hidden");
  input.setAttribute("value", config.areaCode);
  // form.appendChild(input);
  input.id = "pokrName";
  input.name = "pokrName";
  input.setAttribute("type", "hidden");
  input.setAttribute("value", config.pokrName);
  // form.appendChild(input);
  input.id = "code";
  input.name = "code";
  input.setAttribute("type", "hidden");
  input.setAttribute("value", config.code);
  form.appendChild(input);

  // http://localhost:9000/api/dataflow/olfonrealuser/queryRealSum?areaCode=1000&selectDateTime=2022-11-15+10:29:33
  // http://localhost:9000/api/exportExcel
  var url =  http.adornUrl('/dataflow/olxcokrmanagemain/exportExcel');
  form.setAttribute("action", url);
  form.setAttribute("target","downloadIframe");

  //if(config.target == "_blank"){
    //form.setAttribute("target","_blank");
  //}

  console.log("config:"+ JSON.stringify(config))
  // console.log("url:"+ url)
  form.submit();
}